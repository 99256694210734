.containerLogin {
  height: 100vh;
  display: flex;
  justify-content: center;

  :global(.ant-btn:hover:not(.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active)) {
    background: #2387c4;
    color: #fff;
  }
}

.login {
  width: 500px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.formItem {
  width: fit-content;
  margin: 0 auto 8px auto;
  position: relative;

  & input {
    width: 400px;
    height: 40px;
    background: #E9ECF6;
    border-radius: 5px;
    padding: 0 11px;
    font-weight: 900;
    font-size: 14px;
    line-height: 21px;
  }

  & > span {
    right: 10px;
    top: 35px;
    position: absolute !important;
  }
}

.loginHeader {
  display: flex;
  justify-content: center;
  font-weight: 900;
  font-size: 24px;
  line-height: 36px;
  color: #036EB8;
}

.loginLabel {
  font-weight: 900;
  font-size: 14px;
  line-height: 21px;
  color: #036EB8;
}

.loginButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
}

.identification,
.successPage {
  padding-top: 70px;
}

.identificationTitle,
.successTitle,
.successSubtitle {
  font-weight: 900;
  font-size: 24px;
  line-height: 36px;
  color: #036EB8;
  text-align: center;
}

.identificationBox {
  width: 806px;
  height: 465px;
  background: #E9ECF6;
  border-radius: 10px;
  margin-top: 8px;
  padding: 80px 0;
}

.boxTitle {
  font-weight: 900;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #036EB8;
}

.boxLayout {
  width: 400px;
  margin: auto;
  padding: 57px 0 53px 0;
}

.boxContainer {
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;
}

.textFirst {
  font-weight: 900;
  font-size: 8px;
  line-height: 12px;
  text-align: center;
  color: #036EB8;
}

.textSecond {
  font-weight: 900;
  font-size: 14px;
  line-height: 21px;
  color: #036EB8;
}

.boxRight {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #025FA1;
}

.centerText {
  font-weight: 900;
  font-size: 14px;
  line-height: 21px;
  color: #036EB8;
}

.number {
  font-weight: 400;
  font-size: 18px;
  line-height: 44px;
  color: #025FA1;
  padding-left: 18px;
}

.identificationButtons {
  display: flex;
  justify-content: center;

  & button {
    margin-right: 16px
  }
}

.successTitle {
  padding-bottom: 100px;
}

.successSubtitle {
  padding-top: 24px;
}
